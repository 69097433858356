import {BlockFooter, BlockTitle, f7, f7ready, Link, List, ListInput, ListItem, Page, Toggle} from "framework7-react";
import React, {useEffect, useReducer, useRef, useState} from "react";
import gray from '../../assets/icons/colors/gray.svg'
import {createCar, deleteCar, getCar, getHandbook, updateCar} from "../../api/car";
import {useTranslation} from "react-i18next";

export default function CarEdit(props) {
    f7ready(() => {
        // Framework7 is initialized, now you can safely use its APIs
    });

    const {car} = props
    const [curCar, setCurCar] = useState();
    const {t, i18n} = useTranslation();

    const [isLocalImg, setIsLocalImg] = useState(false)
    useEffect(() => {
        const fetchCarByID = async () => {
            const carByID = await getCar(car.id)
            setCurCar(carByID)

        }

        fetchCarByID()
    }, [])

    const validateForm = () => {
        const errors = {}
        if (!brand) errors.brand = 'Укажите марку'
        if (!carModel) errors.carModel = 'Укажите модель';
        if (!carPosition) errors.carPosition = 'Укажите позицию';
        if (!color) errors.color = t('pickColorErr');
        if (!description.trim()) errors.description = t('pickDescriptionErr');
        if (!year) errors.year = t('pickYearErr');
        if (!transmissionType) errors.transmission = t('pickTransmissionErr');
        if (!price.trim() || isNaN(+price) || price > 999999 || price < 200) errors.price = t('pickCostErr')
        if (!mileage.trim() || isNaN(+mileage) || mileage > 999999) errors.mileage = t('pickMileageErr')
        localDispatch({type: 'setErrors', payload: errors})
        return Object.keys(errors).length === 0;
    };

    const [brandOptions, setBrandOptions] = useState(JSON.parse(localStorage.getItem('brandOptions')));
    const [transmissionOptions, setTransmissionOptions] = useState(JSON.parse(localStorage.getItem('transmissionOptions')));
    const [equipmentOptions, setEquipmentOptions] = useState(JSON.parse(localStorage.getItem('equipmentOptions')));
    const [colorOptions, setColorOptions] = useState(JSON.parse(localStorage.getItem('colorOptions')));
    const [addonOptions, setAddonOptions] = useState(JSON.parse(localStorage.getItem('addonOptions')));
    const [saletypeOptions, setSaletypeOptions] = useState(JSON.parse(localStorage.getItem('saletype')));
    const [fueltypeOptions, setFueltypeOptions] = useState(JSON.parse(localStorage.getItem('fueltype')));
    const [opticsOptions, setOpticsOptions] = useState(JSON.parse(localStorage.getItem('optics')));


    useEffect(() => {
        console.log(saletypeOptions)
    }, [])


    const [brand, setBrand] = useState();
    const [carModel, setCarModel] = useState({ value: '', text: '' });
    const [carPosition, setCarPosition] = useState({ value: '', text: '' });
    const [transmissionType, setTransmissionType] = useState({ value: '', text: '' })
    const [options, setOptions] = useState([]);
    const [saletype, setSaletype] = useState([]);
    const [fueltype, setFueltype] = useState('');
    const [optics, setOptics] = useState([]);
    const [color, setColor] = useState("")
    const [year, setYear] = useState('');
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState('');
    const [mileage, setMileage] = useState('');
    const [isTrade, setIsTrade] = useState();
    const [image, setImage] = useState([]);
    const [fileURL, setFileURL] = useState([]);



    const initialState = {
        errors: {},
    };

    function formReducer(state, action) {
        switch (action.type) {
            case 'field':
                return {
                    ...state,
                    [action.fieldName]: action.payload,
                };
            case 'setErrors':
                return {
                    ...state,
                    errors: action.payload,
                };
            default:
                return state;
        }
    }

    const [state, localDispatch] = useReducer(formReducer, initialState);




    const getModelsForSelectedBrand = (brandId) => {
        const selectedBrand = brandOptions.find(b => b.id === parseInt(brandId));
        return selectedBrand ? selectedBrand.models : [];
    };

    const getEquipmentForSelectedModel = (modelId) => {
        return equipmentOptions.filter(equipment => equipment.model_id === parseInt(modelId));
    };


    const pickerDevice = useRef(null);
    const [years, setYears] = useState(() => {
        const newYears = [];
        for (let i = 2000; i < 2024; i++) {
            newYears.push(i);
        }
        return newYears;
    });

    const handleFileChange = (e) => {
        const newFiles = e.target.files;
        const filesArray = Array.from(newFiles)

        if (fileURL.length < 3) {
            setFileURL([...fileURL,newFiles[0]]);
        } else {
            fileURL.pop()
            setFileURL([...fileURL, newFiles[0]])
        }
        setImage([...image, ...filesArray])
    };


    const handleOptionsSelectChange = (e) => {
        const values = Array.from(e.target.selectedOptions, option => option.value)
        setOptions(values)
    }
    const handleOpticsSelectChange = (e) => {
        const values = Array.from(e.target.selectedOptions, option => option.value)
        setOptics(values)
    }

    const handleSaletypeChange = (e) => {
        const values = Array.from(e.target.selectedOptions, option => option.value)
        setSaletype(values)
    }

    const onPageInit = () => {
        pickerDevice.current = f7.picker.create({
            inputEl: '#demo-picker-device',
            cols: [
                {
                    textAlign: 'center',
                    values: years,
                    onChange: function (picker, value) {
                        setYear(value)
                    }
                },
            ],

        });
        pickerDevice.current.setValue([car.year])
    }






    //init inputs
    useEffect(() => {
        setImage(car?.images)
        setFileURL(car?.images)
        setDescription(curCar?.description) // need to add
        setYear(curCar?.year) // need to add
        setPrice(curCar?.price_usd.toString())
        setBrand(curCar?.brand_id.id)
        setColor(curCar?.color_id.id)
        setMileage(curCar?.mileage.toString()) // need to add
        setCarModel(curCar?.model_id.id)
        setTransmissionType(curCar?.transmission_type_id.id)
        setCarPosition(curCar?.equipment_id.id)
        setOptions(curCar?.options.map((option) => option.id))
        setOptics(curCar?.optics.map((optic) => optic.id))
        setSaletype(curCar?.sale_types.map((st) => st.id))
        setFueltype(curCar?.fuel_type_id?.id || "")
        setIsTrade(curCar?.is_bargain)

    }, [curCar])


    const rmCar = (id) => {
        f7.views.main.router.navigate('/delete', {props: {car}})
    }

    const formData = new FormData();
    async function handleMainBtn() {
        if (validateForm()) {
            if (image.length !==0)  {image?.forEach((img, index) => {
                formData.append('images', img);
            })}
            formData.append('year', year)
            if (fueltype) {
                console.log('fuel type:', fueltype)
                formData.append('fuel_type_id', fueltype)
            }
            formData.append('mileage', mileage)
            formData.append('price_usd', price)
            formData.append('description', description)
            formData.append('brand_id', brand)
            formData.append('model_id', carModel)
            formData.append('equipment_id', carPosition)
            formData.append('color_id', color)
            formData.append('is_bargain', isTrade)
            formData.append('transmission_type_id', transmissionType)
            options.forEach((option, index) => {
                formData.append('options', option)
            })
            optics.forEach((optic, index) => {
                formData.append('optics', optic)
            })
            saletype.forEach((salet, index) => {
                formData.append('sale_types', salet)
            })
            window.Telegram.WebApp.MainButton.showProgress((leave = false) => {
            })
            const res = await updateCar(car.id, formData)
            f7.views.main.router.navigate('/mycars');
            window.Telegram.WebApp.MainButton.hideProgress()
        }
    }

    function handleBackBtn() {
        f7.views.main.router.navigate('/mycars');
    }
    useEffect(() => {
        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.MainButton.text = t('saveChangesBtn')
    }, [])

    useEffect(() => {
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn);

        f7.on('pageBeforeRemove', () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        });

        return () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        }
    }, [handleMainBtn, window.Telegram.WebApp.MainButton.isVisible])



    useEffect(() => {
        const inputs = document.querySelectorAll('input, textarea');
        inputs.forEach(input => {
            input.addEventListener('focus', () => {
                setTimeout(() => {
                    input.scrollIntoView({behavior: 'smooth'});
                }, 300);
            });
        });
    }, [handleMainBtn, brand, color])


    const handleDelete = (ind) => {
        setFileURL(fileURL.filter((item) => item !== fileURL[ind]))
        setImage(image.filter((item) => item !== image[ind]))
    }

    useEffect(() => {
        if (pickerDevice.current) {
            pickerDevice.current.destroy();
        }

        pickerDevice.current = f7.picker.create({
            inputEl: '#demo-picker-device',
            cols: [
                {
                    textAlign: 'center',
                    values: years,
                    onChange: function (picker, value) {
                        setYear(value);
                    }
                },
            ],

        });
        pickerDevice.current.setValue([curCar?.year])
    }, [brand,carModel, carPosition, curCar]);

    const getSvgDataUrl = (colorCode) => {
        const svg = `
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="${colorCode}"/>
    </svg>
  `;
        const encodedSvg = btoa(svg.trim());
        return `data:image/svg+xml;base64,${encodedSvg}`;
    };





    return (
        <Page onPageAfterOut={() => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        }} onPageInit={onPageInit}>

            <List key={`${year}-model-list`} style={{marginTop: 20}} strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                          title={t('brand')}
                          smartSelect
                >
                    <select value={brand} onChange={(e) => {setBrand(e.target.value); setCarPosition(''); setCarModel('');}}>
                        <option value=""></option>
                        {brandOptions.map(brandOption => (
                            <option
                                key={brandOption.id}
                                data-option-image={`https://selluzauto.uz/${brandOption.logo}`}
                                value={brandOption.id}>{brandOption.name}
                            </option>
                        ))}
                    </select>
                </ListItem>
            </List>
            {brand && (
                <div>
                    <List key={`${brand}-model-list`} style={{ marginTop: -10 }} strongIos dividersIos insetIos>
                        <ListItem smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                        }}
                                  title={t('model')}
                                  smartSelect
                        >
                            <select value={carModel} onChange={(e) => {setCarModel(e.target.value); setCarPosition('');}}>
                                <option disabled value=""></option>
                                {getModelsForSelectedBrand(brand).map(model => (
                                    <option key={model.id} value={model.id}>{model.name}</option>
                                ))}
                            </select>
                        </ListItem>
                    </List>


                </div>
            )}
            {carModel && (
                <div>
                    <List key={`${carModel}-${brand}-equipment-list`} style={{ marginTop: -10 }} strongIos dividersIos insetIos>
                        <ListItem smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                        }}
                                  title={t('position')}
                                  smartSelect
                        >
                            <select value={carPosition} onChange={(e) => setCarPosition(e.target.value)}>
                                <option value=""></option>
                                {getEquipmentForSelectedModel(carModel).map(equipment => (
                                    <option key={equipment.id} value={equipment.id}>
                                        {i18n.language === 'ru' ? equipment.name_ru : equipment.name_uz}
                                    </option>
                                ))}
                            </select>
                        </ListItem>
                    </List>
                </div>
            )}

            <BlockFooter style={{marginTop: -20}}>{t('provideCarData')}</BlockFooter>
            {(state.errors.brand || state.errors.carPosition ||  state.errors.carModel)  && <BlockFooter style={{marginTop: -15, color:"#FF3B30"}}>{t('provideCarDataErr')}</BlockFooter>}


            <List style={{marginTop: -10}} strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                          title={t('transmission')}
                          smartSelect
                >
                    <select value={transmissionType} onChange={(e) => setTransmissionType(e.target.value) } name="transmission">
                        <option disabled></option>

                        {transmissionOptions.map(trans => (
                            <option
                                key={trans.id}
                                value={trans.id}
                            >
                                {i18n.language === 'ru' ? trans.name_ru : trans.name_uz}
                            </option>
                        ))}
                    </select>
                </ListItem>
            </List>
            <BlockFooter>{t('transmission')}</BlockFooter>
            {(state.errors.transmission)  && <BlockFooter style={{marginTop: -15, color:"#FF3B30"}}>{state.errors.transmission}</BlockFooter>}



            <div className="carform-ctn">
                <List strongIos dividersIos insetIos>
                    <ListItem className={"file-input-ctn"}>
                        <label className="custom-file-label" htmlFor="file-input">
                            {t('pickImage')}
                        </label>
                        <input
                            id="file-input"
                            type="file"
                            className="custom-file-input"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                    </ListItem>


                    {fileURL.length !== 0 && fileURL.map( (url, index) => (<ListItem
                        title={`${t('img')} ${index + 1}`}
                    >
                        <Link onClick={() => handleDelete(index)} slot="after" >
                            <i className="icon f7-icons" style={{color: 'gray'}}>multiply</i>
                        </Link>
                        <img
                            alt="car_img"
                            slot="media"
                            style={{ borderRadius: '8px' }}
                            src={typeof(url) === 'string' ? `https://selluzauto.uz/${url}` : URL.createObjectURL(url)}
                            width="50"
                            height="50"
                        />
                    </ListItem>))}
                </List>
                <List strongIos dividersIos insetIos>
                    <ListInput
                        errorMessage={state.errors.description}
                        errorMessageForce={state.errors.description}
                        type="textarea"
                        placeholder={t('description')}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxlength={200}
                        minlength={50}
                    />
                </List>
                <BlockFooter>{t('writeDescription')}</BlockFooter>
                <List strongIos dividersIos insetIos>
                    <ListItem className={state.errors.price && 'item-input-invalid'}>
                        <div className="item-input-wrap">
                            <input
                                type="text"
                                inputMode="numeric"
                                placeholder={t('cost')}
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                            {state.errors.price && <div className="input-error-message">{state.errors.price}</div>}
                        </div>
                        <div className="item-after">USD</div>
                    </ListItem>
                </List>
                <BlockFooter>{t('priceSum')} {Number.parseInt(curCar?.price_uzs).toLocaleString('en-US').replace(/,/g, ' ')} сум</BlockFooter>

                <List strongIos dividersIos insetIos>
                    <ListItem className={state.errors.mileage && 'item-input-invalid'}>
                        <div className="item-input-wrap">
                            <input
                                type="text"
                                inputMode="numeric"
                                placeholder={t('mileage')}
                                value={mileage}
                                onChange={(e) => setMileage(e.target.value)}
                            />
                            {state.errors.mileage && <div className="input-error-message">{state.errors.mileage}</div>}
                        </div>
                        <div className="item-after">KM</div>
                    </ListItem>
                </List>

                <List strongIos dividersIos insetIos>
                    <ListItem
                        title={t('options')}
                        smartSelect
                        smartSelectParams={{
                            openIn: 'popup',
                            popupCloseLinkText:"Готово",
                            inputIconPosition: 'end',
                            on: {
                                opened: function () {
                                    window.Telegram.WebApp.MainButton.isVisible = false;
                                },
                                closed: function () {
                                    window.Telegram.WebApp.MainButton.isVisible = true;
                                },
                            }
                        }}
                    >
                        <select value={options} onChange={(e) => handleOptionsSelectChange(e)} name="car" multiple>
                            {addonOptions.map((option) => (
                                <option value={option.id}>
                                    {i18n.language === 'ru' ? option.name_ru : option.name_uz}
                                </option>
                            ))}
                        </select>
                    </ListItem>
                </List>

                <List style={{marginTop: -10}}  strongIos dividersIos insetIos>
                    <ListItem
                        title={t('optics')}
                        smartSelect
                        smartSelectParams={{
                            openIn: 'popup',
                            popupCloseLinkText:t('ready'),
                            inputIconPosition: 'end',
                            on: {
                                opened: function () {
                                    window.Telegram.WebApp.MainButton.isVisible = false;
                                },
                                closed: function () {
                                    window.Telegram.WebApp.MainButton.isVisible = true;
                                },
                            }
                        }}
                    >
                        <select value={optics} onChange={(e) => handleOpticsSelectChange(e)} name="car" multiple>
                            {opticsOptions.map((optic) => (
                                <option value={optic.id}>
                                    {i18n.language === 'ru' ? optic.name_ru : optic.name_uz}
                                </option>
                            ))}
                        </select>
                    </ListItem>
                </List>

                <List style={{marginTop: -10}} strongIos dividersIos insetIos>
                    <ListItem
                        title={t('saletype')}
                        smartSelect
                        smartSelectParams={{
                            openIn: 'popup',
                            popupCloseLinkText:t('ready'),
                            inputIconPosition: 'end',
                            on: {
                                opened: function () {
                                    window.Telegram.WebApp.MainButton.isVisible = false;
                                },
                                closed: function () {
                                    window.Telegram.WebApp.MainButton.isVisible = true;
                                },
                            }
                        }}
                    >
                        <select value={saletype} onChange={(e) => handleSaletypeChange(e)} name="car" multiple>
                            {saletypeOptions.map((salet) => (
                                <option value={salet.id}>
                                    {i18n.language === 'ru' ? salet.name_ru : salet.name_uz}
                                </option>
                            ))}
                        </select>
                    </ListItem>
                </List>

                <List style={{marginTop: -10}} strongIos dividersIos insetIos>
                    <ListItem smartSelectParams={{
                        openIn: 'popup',
                        inputIconPosition: 'end',
                        cssClass: "strongIos dividersIos insetIos",
                        closeOnSelect: true,

                    }}
                              title={t('fueltype')}
                              smartSelect
                    >
                        <select value={fueltype}  onChange={(e) => setFueltype(e.target.value) } name="car">
                            <option value="" defaultChecked disabled></option>

                            {fueltypeOptions.map(fuel => (
                                <option
                                    key={fuel.id}
                                    value={fuel.id}
                                >
                                    {i18n.language === 'ru' ? fuel.name_ru : fuel.name_uz}
                                </option>
                            ))}
                        </select>
                    </ListItem>
                </List>



                <List style={{marginTop: -10}} strongIos dividersIos insetIos>
                    <ListItem smartSelectParams={{
                        openIn: 'popup',
                        inputIconPosition: 'end',
                        cssClass: "strongIos dividersIos insetIos",
                        closeOnSelect: true,

                    }}
                              title={t('color')}
                              smartSelect
                    >
                        <select value={color}  onChange={(e) => setColor(e.target.value) } name="car">
                            <option disabled></option>

                            {colorOptions.map(color => (
                                <option
                                    key={color.id}
                                    data-option-image={getSvgDataUrl(color.code)}
                                    value={color.id}
                                >
                                    {i18n.language === 'ru' ? color.name_ru : color.name_uz}
                                </option>
                            ))}
                        </select>
                    </ListItem>
                </List>
                <BlockFooter>{t('carColor')}</BlockFooter>


                <List strongIos dividersIos insetIos>
                    <ListInput
                        type="text"
                        placeholder={t('chooseYear')}
                        readonly
                        inputId="demo-picker-device"
                    />
                </List>
                <BlockFooter>{t('pickYear')}</BlockFooter>

                <List strongIos dividersIos insetIos>
                    <ListItem >
                        <span>{t('canTrade')}</span>
                        <Toggle defaultChecked={isTrade} onToggleChange={(checked) => setIsTrade(checked)} />
                    </ListItem>
                </List>
                <BlockFooter>{t('canTradeDesc')} </BlockFooter>

                <div onClick={() => rmCar(car.id)}>
                    <List strongIos dividersIos insetIos>
                        <ListItem className="delete-button" style={{display: 'flex', justifyContent: 'center', width: "100%"}}>
                            <label style={{color: 'red'}}>{t('deleteCar')}</label>
                        </ListItem>
                    </List>
                </div>
            </div>
        </Page>
    );
}