import {BlockFooter, BlockHeader, BlockTitle, f7, f7ready, List, ListInput, ListItem, Page} from "framework7-react";
import React, {useEffect, useReducer, useState} from "react";
import logo from "../assets/icons/cars.svg";
import profile from "../assets/icons/profile.svg";
import camera from "../assets/icons/camera.svg";
import {useDispatch, useSelector} from "react-redux";
import {setLegalEntity} from "../store/store";
import {legalPartner} from "../api/partner";
import {useTranslation} from "react-i18next";



export default function LegalEntityForm (props) {
    f7ready(() => {
    });

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.legalEntity);
    const formData = new FormData();


    const {address} = props.address === undefined ? " " : props
    const [dealerName, setDealerName] = useState(data?.dealerName || "");
    const [fileURL, setFileURL] = useState('')
    const [image, setImage]= useState("")

    const initialState = {
        errors: {},
    };

    function formReducer(state, action) {
        switch (action.type) {
            case 'field':
                return {
                    ...state,
                    [action.fieldName]: action.payload,
                };
            case 'setErrors':
                return {
                    ...state,
                    errors: action.payload,
                };
            default:
                return state;
        }
    }

    const [state, localDispatch] = useReducer(formReducer, initialState);

    const validateForm = () => {
        const errors = {}
        if (!fileURL) errors.fileURL = "Укажите логотип"
        if (!dealerName.trim()) errors.dealerName = "Укажите цену"
        if (!address?.address1.trim()) errors.address = "Укажите адрес"
        localDispatch({type: 'setErrors', payload: errors})
        console.log(errors)
        return Object.keys(errors).length === 0;
    };

    const handleFileChange = (e) => {
        const file = e.target.files;
        console.log(file)
        if (file[0]) {
            const filesArray = Array.from(file);
            console.log(filesArray)
            setFileURL(filesArray)
            setImage(URL.createObjectURL(file[0]))
        }
    };

    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }

    const truncatedAddress = address?.address1 && truncateText(address?.address1, 20);

    async function handleMainBtn () {
        if (validateForm()) {
            window.Telegram.WebApp.MainButton.showProgress((leave = true) => {})
            dispatch(setLegalEntity({dealerName,  fileURL, address}))
            fileURL?.forEach((image, index) => {
                formData.append('logo', image);
            });
            formData.append('lat', address?.address2.lat)
            formData.append('long', address?.address2.lng)
            formData.append('name', dealerName)

            const res = await legalPartner(formData)

            window.Telegram.WebApp.MainButton.hideProgress()
            localStorage.setItem("requestSent", 'yes')
            f7.views.main.router.navigate('/main')
        }
    }
    function handleBackBtn() {
        f7.view.main.router.navigate('/profileedit')
    }
    useEffect(() => {


        window.Telegram.WebApp.BackButton.show()
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn )
        window.Telegram.WebApp.MainButton.isVisible = true
        window.Telegram.WebApp.MainButton.text = t('sendRequestBtn')
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);

        f7.on('pageBeforeRemove', () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        });

        return () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        }
    }, [handleMainBtn])


    return (
        <Page onPageAfterOut={() => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        }}>
            <BlockTitle style={{
                overflow: 'visible',
                width: '300px',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                fontSize: 25, lineHeight: 1.2
            }}>{t('legalEntityForm')}</BlockTitle>

            <List strongIos dividersIos insetIos>
                <ListInput
                    errorMessageForce={state.errors.dealerName}
                    errorMessage={state.errors.dealerName}
                    type="text"
                    value={dealerName}
                    onChange={(e) => setDealerName(e.target.value)}
                    placeholder={t('dealerName')}
                />
            </List>
            <BlockFooter>{t('chooseDealerName')}</BlockFooter>

            <List style={{marginTop: -20}} strongIos dividersIos insetIos>
                <ListItem
                    onClick={() => {f7.views.main.router.navigate("/maps"); dispatch(setLegalEntity({dealerName, fileURL}))}}
                    link="#" title={t('location')}
                    after={truncatedAddress}
                    placeholder="Your name"
                >
                </ListItem>
            </List>
            <BlockFooter>Локация вашего автосалона</BlockFooter>

            <List style={{marginTop: -20}} strongIos dividersIos insetIos>
                <ListItem className={"file-input-ctn"}>
                    <img
                        width={25}
                        src={camera}
                    />
                    <label style={{marginLeft: 10}} className="custom-file-label" htmlFor="file-input12">
                        {t('chooseLogo')}
                    </label>

                    <input
                        id="file-input12"
                        type="file"
                        className="custom-file-input"
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                </ListItem>


                {fileURL && (<ListItem
                    after=""
                >
                    <img
                        slot="media"
                        style={{ borderRadius: '8px' }}
                        src={image}
                        width="50"
                        height="50"
                    />
                </ListItem>)}
            </List>
            {state.errors.address && <BlockFooter style={{color:"#FF3B30"}}>{state.errors.address}</BlockFooter>}
            {state.errors.fileURL && !fileURL && <BlockFooter style={{color:"#FF3B30", marginTop: -20}}>{state.errors.fileURL}</BlockFooter>}

        </Page>
    );
}
