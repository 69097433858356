import axios from 'axios';


export const getPartner = async () => {
    try {
        const res = await axios.get('https://selluzauto.uz/api/partner/')
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const Login = async (data) => {
    try {
        delete axios.defaults.headers.common['Authorization'];

        const res = await axios.post('https://selluzauto.uz/api/token/', data)

        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const updatePartner = async (data) => {
    try {
        return await axios.patch('https://selluzauto.uz/api/partner/', data)
    } catch (e) {
        console.log(e)
    }
}

export const createPartner = async (data) => {
    try {
        return await axios.put('https://selluzauto.uz/api/partner/', data)
    } catch (e) {
        console.log(e)
    }
}

export const legalPartner  = async (data) => {
    try {
        return await axios.post('https://selluzauto.uz/api/legal_partner/', data)
    } catch (e) {
        console.log(e)
    }
}



