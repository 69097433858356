import React, {useEffect, useRef, useState} from 'react'
import {
    BlockHeader,
    BlockTitle,
    Button,
    Card,
    CardContent,
    CardHeader, f7, f7ready,
    Link,
    List,
    ListItem,
    Page
} from "framework7-react";
import sellzLogo from '../assets/sellz_logo.jpg'
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {setCars} from "../store/store";
import {fetchNext, getCars} from "../api/car";
import {useTranslation} from "react-i18next";


const statusTypes = {
    'moderation': {
        text_ru: "На модерации",
        text_uz: "Moderasiada",
        bgColor: 'rgba(232,228,157,0.2)',
        textColor: 'rgba(245,232,26)'
    },
    'active': {
        text_ru: "Активный",
        text_uz: "Active",

        bgColor: 'rgba(114,239,72,0.2)',
        textColor: 'rgb(103,239,72)'
    },
    'inactive': {
        text_ru: "Неактивный",
        text_uz: "Inactive",
        bgColor: 'rgba(143,143,139,0.2)',
        textColor: 'rgba(143,143,139)'
    },
}

const MyCars = () => {
    f7ready(() => {
        // Framework7 is initialized, now you can safely use its APIs
    });

    const {t, i18n} = useTranslation();


    const [nextURL, setNextURL] = useState('');
    const observerRef = useRef(null);
    const lastCarRef = useRef(null);
    const [currentCars, setCurrentCars] = useState([])

    function handleBackBtn() {
        f7.views.main.router.navigate('/main');
    }

    function handleMainBtn () {
        window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        f7.views.main.router.navigate('/carform');
    }

    const fetchNextCars = async (next) => {
        const res = await fetchNext(next)
        setCurrentCars((prevState)=> [...prevState, ...res.results])
        setNextURL(res.next)
        console.log(currentCars)
    }

    useEffect(() => {
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn);
        window.Telegram.WebApp.MainButton.text = t('addCarBtn')

        window.Telegram.WebApp.MainButton.isVisible = false;
        window.Telegram.WebApp.MainButton.isVisible = true;

        f7.on('pageBeforeRemove', () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        });
    }, [window.Telegram.WebApp.MainButton.isVisible])

    useEffect(() => {
        const fetchCars = async () => {
            const data = await getCars();
            setNextURL(data.next)
            setCurrentCars(data.results)
        }
            fetchCars();
    }, [])

    useEffect(() => {
        if (observerRef.current) observerRef.current.disconnect();

        observerRef.current = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && nextURL) {
                    fetchNextCars(nextURL);
                }
            },
            { threshold: 1 }
        );

        if (lastCarRef.current) {
            observerRef.current.observe(lastCarRef.current);
        }

        return () => {
            if (observerRef.current) observerRef.current.disconnect();
        };
    }, [currentCars, nextURL]);



    return (
        <Page onPageAfterOut={() => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        }} >
            <BlockTitle style={{fontSize: 25, lineHeight: 1.2}}>{t('myCars')}</BlockTitle>
            <List dividersIos mediaList insetIos strongIos>
            { (currentCars.length === 0) ?  (<div>{t('yetEmpty')} :(</div>) : currentCars.map((car, index) => (
                    <ListItem
                        onClick={() => { f7.view.main.router.navigate("/caredit", {props: {car}}); }}
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%'}}
                        key={index}
                        link="#"
                        title={car.model_id.name}
                        after=""
                        subtitle={`Цена: ${car.price_usd} $`}
                        text={car.description}
                    >
                        <div ref={index === currentCars.length - 1 ? lastCarRef : null}/>
                         <span  style={{
                            marginTop: 10,
                            fontSize: 14,
                            display: 'inline-block',
                            backgroundColor: `${statusTypes[car?.status].bgColor}`,
                            borderRadius: '8px',
                            padding: '4px 12px',
                            color: `${statusTypes[car?.status].textColor}`,
                            fontWeight: 'bold',}}>{i18n.language === 'ru' ? statusTypes[car?.status].text_ru : statusTypes[car?.status].text_uz}</span>
                        <img
                            slot="media"
                            style={{
                                borderRadius: '8px',
                                objectFit: 'cover'
                            }}
                            src={`https://selluzauto.uz/${car.images[0]}` || sellzLogo}
                            width="90"
                            height="90"
                        />
                    </ListItem>

            ))}
            </List>
        </Page>)
}

export default MyCars;