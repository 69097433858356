
import {
    App,
    BlockFooter, BlockHeader,
    BlockTitle, f7, f7ready,
    Link,
    List,
    ListInput, ListItem, Page,
} from "framework7-react";
import React, {useCallback, useEffect, useReducer, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../store/store";
import {createPartner, updatePartner} from "../api/partner";
import {useTranslation} from "react-i18next";

function Registration(props) {
    f7ready(() => {
    });
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user)

    const [birthday, setBirthday] = useState('')
    const initialState = {
        name: '',
        username: 't.me/',
        phoneNumber: `+${props.phoneNumber}`,
        region: '',
        errors: {},
    };

    function formReducer(state, action) {
        switch (action.type) {
            case 'field':
                return {
                    ...state,
                    [action.fieldName]: action.payload,
                };
            case 'setErrors':
                return {
                    ...state,
                    errors: action.payload,
                };
            default:
                return state;
        }
    }

    const [state, localDispatch] = useReducer(formReducer, initialState);

    const validateForm = () => {
        const errors = {};
        if (!state.name.trim()) errors.name = t('registerWriteName');
        if (!state.username.trim()) errors.username = t('chooseUsernameErr');
        if (state.phoneNumber.trim() === "+998" || Number.isNaN(Number(state.phoneNumber.slice(1))) || state.phoneNumber.length < 13) errors.phoneNumber = t('chooseValidPhone');
        if (!birthday) errors.birthday = t('chooseBirthdayErr');
        if (!state.region.trim()) errors.region = t('chooseRegionErr');

        localDispatch({ type: 'setErrors', payload: errors });
        return Object.keys(errors).length === 0;
    };


    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (!value.startsWith('+998')) {
            localDispatch({ type: 'field', fieldName: 'phoneNumber', payload: "+998" });
        } else {
            localDispatch({ type: 'field', fieldName: 'phoneNumber', payload: value });
        }
    };




    const handleChange = useCallback(
        (e) => {
            const {name, value} = e.target
            localDispatch({type: 'field', fieldName: name, payload: value})
        },
        [],
    );

    //tg related

    const  handleMainBtn = async () => {
        if (validateForm()) {
            dispatch(setUser({name: state.name, username: state.username, birthday,  phoneNumber: state.phoneNumber, region: state.region, entityType: 'person'}))
            window.Telegram.WebApp.MainButton.showProgress((leave = false) => {})

            const res = await createPartner({
                registered_name: state.name,
                username: state.username,
                birthday: birthday.getFullYear() + "-" + (birthday.getMonth()+1) + "-" + birthday.getDate()  ,
                phone_number: state.phoneNumber,
                region: state.region,
                language: i18n.language,
            })
            window.Telegram.WebApp.MainButton.hideProgress()
            f7.views.main.router.navigate("/simplecarform")

            console.log(res.data)
        }

    }
    useEffect(() => {
            localDispatch({ type: 'field', fieldName: 'username', payload: user?.username || ''});
            localDispatch({ type: 'field', fieldName: 'phoneNumber', payload: user?.phoneNumber || `+${props.phoneNumber}` });
    }, [])

    useEffect(() => {
            const user = window.Telegram.WebApp.initDataUnsafe.user;
            if (user) {
                let telegramId = user.id;
                console.log(user.username)
                localDispatch({ type: 'field', fieldName: 'username', payload: user.username });
            }}
        , [])


    useEffect(() => {
        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.MainButton.text = t('goToCarFormBtn');

        window.Telegram.WebApp.BackButton.hide();
    }, []);


    useEffect(() => {
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);

        return (() => {
            window.Telegram.WebApp.offEvent("mainButtonClicked", handleMainBtn)
        })
    }, [handleMainBtn])


    useEffect(() => {
        const inputs = document.querySelectorAll('input, textarea');
        inputs.forEach(input => {
            input.addEventListener('focus', () => {
                setTimeout(() => {
                    input.scrollIntoView({behavior: 'smooth'});
                }, 300);
            });
        });
    }, [handleMainBtn])



    return (
        <Page onPageAfterOut={() => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        }}>
            <BlockTitle style={{fontSize: 25, lineHeight: 1.2}}>{t('register')}</BlockTitle>
            <List strongIos dividersIos insetIos>
                <ListInput
                    maxlength={15}
                    name="name"
                    type="text"
                    value={state.name}
                    onChange={handleChange}
                    errorMessageForce={state.errors.name}
                    errorMessage={state.errors.name}
                    placeholder={t('registerName')}
                />
            </List>
            <BlockFooter>{t('registerWriteName')}</BlockFooter>


            <List strongIos dividersIos insetIos>
                <ListInput
                    errorMessageForce={state.errors.phoneNumber}
                    errorMessage={state.errors.phoneNumber}
                    name="phoneNumber"
                    type={"tel"}
                    inputmode={"numeric"}
                    placeholder="Номер телефона"
                    maxlength={13}
                    value={state.phoneNumber}
                    onChange={handlePhoneNumberChange}
                />
            </List>
            <BlockFooter>{t('registerWritePhone')}</BlockFooter>
            <List strongIos dividersIos insetIos>
                <ListInput
                    name="birthday"
                    type="datepicker"
                    placeholder={t('registerPickDate')}
                    readonly
                    errorMessageForce={state.errors.birthday}
                    errorMessage={state.errors.birthday}
                    calendarParams={{
                        openIn: 'customModal',
                        header: true,
                        footer: true,
                        yearPickerMin: 1950,
                        yearPickerMax: 2010,
                        locale:  i18n.language,
                        toolbarCloseText: t('ready'),
                        touchMove: false,
                        on: {
                            open(cal) {
                                cal.setYearMonth(1990, 1, 0);
                                cal.update();
                            },
                            change(calendar, value) {
                                setBirthday(value[0])
                            }
                        }
                    }}
                />
            </List>
            <BlockFooter>{t('registerPickYourDate')}</BlockFooter>

            <List strongIos dividersIos insetIos>
            <ListItem smartSelectParams={{
                openIn: 'popup',
                inputIconPosition: 'end',
                cssClass: "strongIos dividersIos insetIos",
                closeOnSelect: true,
            }}
                      errorMessageForce={state.errors.region}
                      errorMessage={state.errors.region}
                      title={t('registerRegion')}
                      smartSelect
            >
                <select value={state.region} name="region" onChange={handleChange}
                >
                    <option value='' disabled defaultChecked></option>
                    <option value="Ташкент">{t('regions.tashkent')}</option>
                    <option value="Ташкентская обл">{t('regions.tashkent_obl')}</option>
                    <option value="Фергана">{t('regions.fergana')}</option>
                    <option value="Андижан">{t('regions.andijan')}</option>
                    <option value="Наманган">{t('regions.namangan')}</option>
                    <option value="Бухара">{t('regions.bukhara')}</option>
                    <option value="Навои">{t('regions.navoi')}</option>
                    <option value="Хорезм">{t('regions.khorezm')}</option>
                    <option value="Сурхандарья">{t('regions.surkhandarya')}</option>
                    <option value="Кашкадарья">{t('regions.kashkadarya')}</option>
                    <option value="Сырдарья">{t('regions.syrdarya')}</option>
                    <option value="Джиззак">{t('regions.jizzakh')}</option>
                    <option value="Самарканд">{t('regions.samarkand')}</option>
                    <option value="Каракалпакстан">{t('regions.karakalpakstan')}</option>
                </select>
            </ListItem>
        </List>
            {state.errors.region && state.region === "" && <BlockFooter style={{position: 'absolute',color:"#FF3B30"}}>{state.errors.region}</BlockFooter>}
            <List strongIos dividersIos insetIos>
                <ListInput
                    errorMessageForce={state.errors.username}
                    errorMessage={state.errors.username}
                    name="username"
                    type="text"
                    value={state.username}
                    onChange={handleChange}
                    placeholder="username"
                />
            </List>
            <BlockFooter>{t('registerWriteUsername')}</BlockFooter>
            <div style={{marginBottom: 120}}/>
        </Page>
    );
}

export default Registration;