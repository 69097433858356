import axios from 'axios';

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`


export const getCar = async (id) => {
    try {
        const res = await axios.get(`https://selluzauto.uz/api/car/${id}/`)
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const getCars = async () => {
    try {
        const res = await axios.get('https://selluzauto.uz/api/car/')
        return res.data
    } catch (e) {
        console.log(e)
    }
}


export const createCar = async (data) => {
    try {
        const res = await axios.post('https://selluzauto.uz/api/car/', data)
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const updateCar = async (id, data) => {
    try {
        const res = await axios.put(`https://selluzauto.uz/api/car/${id}/`, data)
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const deleteCar = async (id, data) => {
    try {
        const res = await axios.delete(`https://selluzauto.uz/api/car/${id}/`, {data: data})
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const fetchNext = async (next) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        const res = await axios.get(next);
        return res.data
    } catch (e) {
        console.log(e)
    }
}


export const getHandbooks = async () => {
    try {
        const res = await axios.get(`https://selluzauto.uz/api/generic/`)
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const getHandbook = async (type) => {
    try {
        const res = await axios.get(`https://selluzauto.uz/api/generic/${type}/`)
        return res.data
    } catch (e) {
        console.log(e)
    }
}