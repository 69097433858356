import {
    BlockFooter,
    BlockTitle,
    f7,
    f7ready,
    Icon,
    Link,
    List,
    ListInput,
    ListItem,
    Page,
    Toggle
} from "framework7-react";
import React, {useEffect, useReducer, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {addCar, removeCar} from "../../store/store";
import black from '../../assets/icons/colors/black.svg'
import white from '../../assets/icons/colors/white.svg'
import dolphin from '../../assets/icons/colors/dolphin.svg'
import gray from '../../assets/icons/colors/gray.svg'
import axios from "axios";
import {createCar} from "../../api/car";
import {useTranslation} from "react-i18next";

export default function CarEdit(props) {
    f7ready(() => {
        // Framework7 is initialized, now you can safely use its APIs
    });
    const {t, i18n} = useTranslation();
    const [brandOptions, setBrandOptions] = useState(JSON.parse(localStorage.getItem('brandOptions')));
    // const [modelOptions, setModelOptions] = useState([]);
    const [transmissionOptions, setTransmissionOptions] = useState(JSON.parse(localStorage.getItem('transmissionOptions')));
    const [equipmentOptions, setEquipmentOptions] = useState(JSON.parse(localStorage.getItem('equipmentOptions')));
    const [colorOptions, setColorOptions] = useState(JSON.parse(localStorage.getItem('colorOptions')));
    const [addonOptions, setAddonOptions] = useState(JSON.parse(localStorage.getItem('addonOptions')));
    const formData = new FormData();

    const [isTrade, setIsTrade] = useState(false);
    const [brand, setBrand] = useState({ value: '', text: '' });
    const [carModel, setCarModel] = useState({ value: '', text: '' });
    const [carPosition, setCarPosition] = useState({ value: '', text: '' });
    const [transmissionType, setTransmissionType] = useState({ value: '', text: '' })
    const [mileage, setMileage] = useState("");
    const [options, setOptions] = useState([]);
    const [color, setColor] = useState({ value: '', text: '' })
    const [year, setYear] = useState('');
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState('')
    const [fileURL, setFileURL] = useState([]);
    const [image, setImage] = useState([])
    const [uniqueID, setUniqueID] = useState(Date.now().toString(36))

    const dispatch = useDispatch();

    const validateForm = () => {
        const errors = {}
        if (!brand.value) errors.brand = 'Укажите марку'
        if (!carModel.value) errors.carModel = 'Укажите модель';
        if (!carPosition.value) errors.carPosition = 'Укажите позицию';
        if (!color.value) errors.color = t('pickColorErr');
        if (!description.trim()) errors.description = t('pickDescriptionErr');
        if (!year) errors.year = t('pickYearErr');
        if (!transmissionType.value) errors.transmission = t('pickTransmissionErr');
        if (!price.trim() || isNaN(+price) || price > 999999 || price < 200) errors.price = t('pickCostErr')
        if (!mileage.trim() || isNaN(+mileage) || mileage > 999999) errors.mileage = t('pickMileageErr')
        localDispatch({type: 'setErrors', payload: errors})
        console.log(errors)
        return Object.keys(errors).length === 0;
    };

    function formReducer(state, action) {
        switch (action.type) {
            case 'field':
                return {
                    ...state,
                    [action.fieldName]: action.payload,
                };
            case 'setErrors':
                return {
                    ...state,
                    errors: action.payload,
                };
            default:
                return state;
        }
    }

    const initialState = {
        errors: {},
    };

    const [state, localDispatch] = useReducer(formReducer, initialState);

    const pickerDevice = useRef(null);
    const [years, setYears] = useState(() => {
        const newYears = [];
        for (let i = 2000; i < 2024; i++) {
            newYears.push(i);
        }
        return newYears;
    });

    const handleFileChange = (e) => {
        const newFiles = e.target.files;
        const filesArray = Array.from(newFiles)
        console.log(newFiles[0])
        if (fileURL.length < 3) {
            setFileURL([...fileURL, newFiles[0]]);
        } else {
            fileURL.pop()
            setFileURL([...fileURL, newFiles[0]])
        }
        setImage([...image, ...filesArray])
        /*if (file[0]) {
            const filesArray = Array.from(file);
            setFileURL(filesArray)
            setImage(URL.createObjectURL(file[0]))
        }*/
    };


    const onPageInit = () => {
        pickerDevice.current = f7.picker.create({
            inputEl: '#demo-picker-device',
            cols: [
                {
                    textAlign: 'center',
                    values: years,
                    onChange: function (picker, value) {
                        setYear(value)
                        console.log(year)
                    }
                },
            ],

        });
    }

    useEffect(() => {
        if (pickerDevice.current) {
            pickerDevice.current.destroy();
        }

        pickerDevice.current = f7.picker.create({
            inputEl: '#demo-picker-device',
            cols: [
                {
                    textAlign: 'center',
                    values: years,
                    onChange: function (picker, value) {
                        setYear(value);
                    }
                },
            ],
        });
    }, [brand, color, state.errors]);

    const getModelsForSelectedBrand = (brandId) => {
        const selectedBrand = brandOptions.find(b => b.id === parseInt(brandId));
        return selectedBrand ? selectedBrand.models : [];
    };

    const getEquipmentForSelectedModel = (modelId) => {
        return equipmentOptions.filter(equipment => equipment.model_id === parseInt(modelId));
    };




    async function handleMainBtn() {
        if (validateForm()) {
            image.forEach((img, index) => {
                formData.append('images', img);
            });
            formData.append('year', year)
            formData.append('mileage', mileage)
            formData.append('price_usd', price)
            formData.append('description', description)
            formData.append('brand_id', brand.value)
            formData.append('model_id', carModel.value)
            formData.append('equipment_id', carPosition.value)
            formData.append('color_id', color.value)
            formData.append('is_bargain', isTrade)
            formData.append('transmission_type_id', transmissionType.value)
            window.Telegram.WebApp.MainButton.showProgress((leave = false) => {
            })
            const res = await createCar(formData)
            console.log(res)
            f7.views.main.router.navigate('/mycars');
            window.Telegram.WebApp.MainButton.hideProgress()
        }
    }

    function handleBackBtn() {
        f7.views.main.router.navigate('/mycars');
    }
    useEffect(() => {
        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.MainButton.text = t('addCarBtn')
    }, [])

    useEffect(() => {
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn);

        f7.on('pageBeforeRemove', () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        });

        return () => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        }
    }, [handleMainBtn])


    useEffect(() => {
        const inputs = document.querySelectorAll('input, textarea');
        inputs.forEach(input => {
            input.addEventListener('focus', () => {
                setTimeout(() => {
                    input.scrollIntoView({behavior: 'smooth'});
                }, 300);
            });
        });
    }, [handleMainBtn])

    const handleDelete = (ind) => {
        setFileURL(fileURL.filter((item) => item !== fileURL[ind]))
        setImage(image.filter((item) => item !== image[ind]))
    }


    const getSvgDataUrl = (colorCode) => {
        const svg = `
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="${colorCode}"/>
    </svg>
  `;
        const encodedSvg = btoa(svg.trim());
        return `data:image/svg+xml;base64,${encodedSvg}`;
    };







    return (
        <Page onPageInit={onPageInit}>
            <List style={{marginTop: 20}} strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                          title={t('brand')}
                          smartSelect
                >
                    <select value={brand.value} onChange={(e) => {setBrand({
                        value: e.target.value,
                        text: e.target.options[e.target.selectedIndex].text
                    }); setCarModel({value: '', text: ''}); setCarPosition({value: '', text: ''});}}>
                        <option value="" defaultChecked disabled></option>
                        {brandOptions.map(brand => (
                            <option
                                key={brand.id}
                                data-option-image={`https://selluzauto.uz/${brand.logo}`}
                                value={brand.id}>{brand.name}
                            </option>
                        ))}
                    </select>
                </ListItem>
            </List>
            {brand.value && (
                <div>
                    <List key={`${brand.value}-model-list`} style={{ marginTop: -10 }} strongIos dividersIos insetIos>
                        <ListItem smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                        }}
                                  title={t('model')}
                                  smartSelect
                        >
                            <select value={carModel.value} onChange={(e) => setCarModel({
                                value: e.target.value,
                                text: e.target.options[e.target.selectedIndex].text
                            })}>
                                <option defaultChecked disabled value=""></option>
                                {getModelsForSelectedBrand(brand.value).map(model => (
                                    <option key={model.id} value={model.id}>{model.name}</option>
                                ))}
                            </select>
                        </ListItem>
                    </List>


                </div>
            )}
            {carModel.value && (
                <div>
                    <List key={`${carModel.value}-equipment-list`} style={{ marginTop: -10 }} strongIos dividersIos insetIos>
                        <ListItem smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                        }}
                                  title={t('position')}
                                  smartSelect
                        >
                            <select value={carPosition.value} onChange={(e) => setCarPosition({
                                value: e.target.value,
                                text: e.target.options[e.target.selectedIndex].text
                            })}>
                                <option defaultChecked disabled value=""></option>
                                {getEquipmentForSelectedModel(carModel.value).map(equipment => (
                                    <option key={equipment.id} value={equipment.id}>
                                        {i18n.language === 'ru' ? equipment.name_ru : equipment.name_uz}
                                    </option>
                                ))}
                            </select>
                        </ListItem>
                    </List>
                </div>
            )}
            <BlockFooter style={{marginTop: -20}}>{t('provideCarData')}</BlockFooter>
            {((state.errors.brand || state.errors.carPosition ||  state.errors.carModel) && (!brand.value || !carModel.value || !carPosition.value))  && <BlockFooter style={{marginTop: -25, color:"#FF3B30"}}>{t('provideCarDataErr')}</BlockFooter>}
            <List style={{marginTop: -10}} strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                          title={t('transmission')}
                          smartSelect
                >
                    <select value={transmissionType.value} onChange={(e) => setTransmissionType({
                        value: e.target.value,
                        text: e.target.options[e.target.selectedIndex].text
                    }) } name="transmission">
                        <option value="" defaultChecked disabled></option>

                        {transmissionOptions.map(trans => (
                            <option
                                key={trans.id}
                                value={trans.id}
                            >
                                {i18n.language === 'ru' ? trans.name_ru : trans.name_uz}
                            </option>
                        ))}
                    </select>
                </ListItem>
            </List>
            <BlockFooter>{t('transmission')}</BlockFooter>
            {(state.errors.transmission && !transmissionType.value)   && <BlockFooter style={{marginTop: -25, color:"#FF3B30"}}>{state.errors.transmission}</BlockFooter>}

            <div className="carform-ctn">
                <List strongIos dividersIos insetIos>
                    <ListItem className={"file-input-ctn"}>
                        <label className="custom-file-label" htmlFor="file-input">
                            {t('pickImage')}
                        </label>
                        <input
                            id="file-input"
                            type="file"
                            className="custom-file-input"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                    </ListItem>


                    {fileURL[0] && (fileURL.map( (url, index) => (<ListItem

                        title={`${t('img')} ${index + 1}`}


                    >
                        <Link slot="after" onClick={() => handleDelete(index)}>
                            <i className="icon f7-icons" style={{color: 'gray'}}>multiply</i>
                        </Link>
                        <img
                            alt="car_img"
                            slot="media"
                            style={{ borderRadius: '8px' }}
                            src={URL.createObjectURL(url)}
                            width="50"
                            height="50"
                        />
                    </ListItem>)))}
                </List>
                {state.errors.fileURL && !fileURL && <BlockFooter style={{position: 'absolute',color:"#FF3B30"}}>{state.errors.fileURL}</BlockFooter>}
                <List strongIos dividersIos insetIos>
                    <ListInput
                        errorMessage={state.errors.description}
                        errorMessageForce={state.errors.description}
                        type="textarea"
                        placeholder={t('description')}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxlength={200}
                        minlength={50}
                    />
                </List>
                <BlockFooter>{t('writeDescription')}</BlockFooter>
                <List strongIos dividersIos insetIos>
                    <ListItem className={state.errors.price && 'item-input-invalid'}>
                        <div className="item-input-wrap">
                            <input
                                type="text"
                                inputMode="numeric"
                                placeholder={t('cost')}
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                            {state.errors.price && <div className="input-error-message">{state.errors.price}</div>}
                        </div>
                        <div className="item-after">USD</div>
                    </ListItem>
                </List>

                <List strongIos dividersIos insetIos>
                    <ListItem className={state.errors.mileage && 'item-input-invalid'}>
                        <div className="item-input-wrap">
                            <input
                                type="text"
                                inputMode="numeric"
                                placeholder={t('mileage')}
                                value={mileage}
                                onChange={(e) => setMileage(e.target.value)}
                            />
                            {state.errors.mileage && <div className="input-error-message">{state.errors.mileage}</div>}
                        </div>
                        <div className="item-after">KM</div>
                    </ListItem>
                </List>


                <List style={{marginTop: -10}} strongIos dividersIos insetIos>
                    <ListItem smartSelectParams={{
                        openIn: 'popup',
                        inputIconPosition: 'end',
                        cssClass: "strongIos dividersIos insetIos",
                        closeOnSelect: true,
                    }}
                              title={t('color')}
                              smartSelect
                    >
                        <select value={color.value} onChange={(e) => setColor({
                            value: e.target.value,
                            text: e.target.options[e.target.selectedIndex].text
                        }) } name="car">
                            <option value="" defaultChecked disabled></option>

                            {colorOptions.map(color => {
                                console.log(white)
                                return (<option
                                    key={color.id}
                                    data-option-image={getSvgDataUrl(color.code)}
                                    value={color.id}
                                >
                                    {i18n.language === 'ru' ? color.name_ru : color.name_uz}
                                </option>)
                            })}
                        </select>
                    </ListItem>
                </List>
                {state.errors.color && !color.value && <BlockFooter style={{position: 'absolute',color:"#FF3B30"}}>{state.errors.color}</BlockFooter>}

                <List strongIos dividersIos insetIos>
                    <ListInput
                        errorMessage={state.errors.year}
                        errorMessageForce={state.errors.year}
                        type="text"
                        placeholder={t('chooseYear')}
                        readonly
                        inputId="demo-picker-device"
                    />
                </List>
                <BlockFooter>{t('pickYear')}</BlockFooter>

                <List strongIos dividersIos insetIos>
                    <ListItem >
                        <span>{t('canTrade')}</span>
                        <Toggle onToggleChange={(checked) => setIsTrade(checked)}/>
                    </ListItem>
                </List>
                <BlockFooter>{t('canTradeDesc')} </BlockFooter>

            </div>
        </Page>
    );
}

